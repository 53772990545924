<template>
  <div class="Integral  ">
    <div class="   ">
    <div class="top">
      <p>我的积分</p>
    </div>
    <div class="usable">
      <div class="usaInt">
        <span>可用积分</span>
        <span>{{integralTotal}}</span>
        <span>可抵扣{{integralTotal}}元</span>
      </div>
      <el-button type="primary" class="jfdh" @click="toChange">积分兑换</el-button>
    </div>
    <div class="scoreList">
      <ul>
        <li class="masthead ">
          <span>来源/用途</span>
          <span>积分变化</span>
          <span>日期</span>
          <!-- <span>备注</span> -->
        </li>
        <li
          v-for="item in integral"
          :key="item.id"
        >
          <div class="source">
            <p v-if="item.origin_type == 1">签到</p>
            <p v-if="item.origin_type == 2">连续签到</p>
            <p v-if="item.origin_type == 3">连续签到最大天数送积分</p>
            <p v-if="item.origin_type == 4">注册获得</p>
            <p v-if="item.origin_type == 5">交易获得</p>
          </div>
          <div class="change">
            <p v-if="item.change_type == 1">+{{item.scrore_value}}</p>
            <p v-if="item.change_type == 0">-{{item.scrore_value}}</p>
          </div>
          <div class="time">
            {{new Date(item.created_at).format('yyyy-MM-dd HH:mm')}}
          
          </div>

        </li>
      </ul>
    </div> </div>
    <div style="text-align:center;margin-top:20px">
      <el-pagination
        @current-change="currentChange"
        :current-page.sync="where.page"
        :page-sizes="[10, 20, 30, 40]"
        :page-size.sync="where.limit"
        :total="total"
      >
      </el-pagination>
    </div>

  </div>
</template>

<script type="text/javascript">
export default {
  data() {
    return {
      integral: [],
      total_num: 0,
      total: 0,
      integralTotal:0,
      where: {
        member_id: this.$store.state.member.id,
        page: 1,
        limit: 10
      }
    };
  },
  filters: {},
  methods: {
  toChange(e) {
      let routeData = {};

      routeData = this.$router.resolve({
        path: "/member-integral-change",
     
      });

      window.open(routeData.href, "_blank");
    },
     // 获取积分
    getIntegral() {
      this.$get("home/score", { member_id: this.$store.state.member.id }).then((res) => {
        this.integralTotal = 0;
        let integral = res.items.data;
        integral.forEach((item) => {
          this.integralTotal += item.scrore_value;
        });
      });
    },
    currentChange() {
      this.$get("home/score", this.where).then(res => {
        this.integral = res.items.data;
        
        this.total = res.items.total;
      });
    }
  },
  components: {},
  created() {
    this.getIntegral()
    this.currentChange();
  }
};
</script>

<style lang="scss" scoped>
 
@import "@/assets/styles/mixins";

.Integral {
  width: 100%;
  background: #fff;
  .top {
    p {
      font-size: 16px;
      font-weight: 700;
      height: 35px;
      width: 100%;
      line-height: 35px;
      border-bottom: 2px solid #757575;
    }
  }
  .usable {
    margin-top: 15px;
    height: 160px;
    width: 100%;
    padding: 28px 0;
    border: 2px solid #e2e2e2;
    background-color: #f1f1f1;
    .usaInt {
      width: 265px;
      float: left;
      height: 100px;
      border-right: 1px solid #e2e2e2;
    }
    .usaInt span:nth-child(1) {
      font-size: 18px;
      font-weight: 700;
      color: #666;
      height: 24px;
      line-height: 24px;
      display: block;
      text-indent: 60px;
      text-align: left;
    }
    .usaInt span:nth-child(2) {
      font-size: 34px;
      font-weight: 700;
      color: #288c29;
      height: 40px;
      line-height: 40px;
      display: block;
      text-indent: 60px;
      text-align: left;
    }
    .usaInt span:nth-child(3) {
      font-size: 14px;
      font-weight: 700;
      color: #666;
      height: 24px;
      line-height: 24px;
      display: block;
      text-indent: 60px;
      text-align: left;
    }
  }
  .scoreList {
    ul {
      .masthead {
        padding: 0;
        margin-top: 15px;
        height: 35px;
        width: 100%;
        line-height: 35px;
        background-color: #f1f1f1;
        border-width: 1px 0;
        border-color: #e3e3e3;
        border-style: solid;
        color: #999;
        span {
          display: block;
          float: left;
          border-right: 1px solid #e3e3e3;
          height: 32px;
          line-height: 32px;
          text-align: center;
          overflow: hidden;
        }
      }
      .masthead span:nth-child(1) {
        width: 380px;
      }
      .masthead span:nth-child(2) {
        width: 210px;
      }
      .masthead span:nth-child(3) {
        width: 400px;
      }
      // .masthead span:nth-child(4){
      //   width: 120px;
      // }
      li {
        width: 100%;

        border-bottom: 1px solid #f1f1f1;
        @include space-between;
        div {
          line-height: 60px;
        }
        .source {
          width: 380px;
          height: 100%;
          text-align: center;
        }
        .change {
          width: 210px;
          height: 100%;
          text-align: center;
          p {
            font-size: 24px;
            font-weight: 700;
            line-height: 60px;
            color: #b1000f;
          }
        }
        .time {
          width: 400px;
          height: 100%;
          text-align: center;
        }
        .typeOf {
          width: 120px;
          height: 100%;
          text-align: center;
        }
      }
    }
  }
}
.jfdh{ float: right;margin-top:30px;margin-right:50px}
</style>
